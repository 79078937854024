<template>
  <div>
    <BaseGeometry
      :value="detail"
      geometryType="passport"
      v-if="detail"
      @loaded="loadedGeometry"
      :key="keyMap"
      :disabled="!isCanInteractionPassport"
      height="100vh"
    />
  </div>
</template>

<script>
import common from '@/components/mixins/map/common'
import controlLayer from '@/components/mixins/map/helpers/mapInit'
import mapStore from '@/components/mixins/map/store'
import BaseGeometry from '@/components/base/BaseGeometry'
import { cleanClone } from '@/components/utils/common'
import userInteraction from '@/components/mixins/userInteraction'

export default {
  name: 'PassportMap',
  mixins: [controlLayer, common, mapStore, userInteraction],
  components: { BaseGeometry },
  props: {
    value: Object
  },
  data () {
    return {
      keyMap: 11,
      detail: cleanClone(this.value)
    }
  },
  methods: {
    async loadedGeometry (data) {
      this.$set(this, 'detail', data)
      this.$set(this, 'keyMap', this.keyMap + 1)
    }
  }
}
</script>
