import L from 'leaflet'

export default {
  methods: {
    flyTo (target, zoom, options = {}) {
      if (target instanceof L.Polygon) {
        this.map.flyToBounds(target.getBounds(), options)
      } else {
        this.map.flyTo(target.getLatLng(), zoom, options)
      }
    },
    handleMapClick (e) {
      let pixelPosition = e.layerPoint
      let latLng = this.map.layerPointToLatLng(pixelPosition)
      // console.log('Pixel position = ' + pixelPosition + '\nLatLng = ' + latLng.lng + ' ' + latLng.lat)
      // console.log('Zoom level: ' + this.map.getZoom()) Вдруг нужны будут потом, оставлю закомментированными.
    }
  }
}
